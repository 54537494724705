import AyeLabVideo from '../../assets/videos/ayeLAB-Presentation-7-Final.mp4';
import Footer from '../layouts/Footer';
import Navigation from '../layouts/Navigation';


function Videos() {

    return (
        <div className="min-h-screen h-full flex flex-col">
            <Navigation />
            <div className="min-h-[160px] bg-[url('assets/images/header_background.png')] px-16 py-12 mt-12">
                <p className="text-white text-5xl">Videos</p>
            </div>
            <div className='grow my-16 '>

                <div className='flex justify-center'>

                    <div className='basis-[1000px] w-[1000px]'>
                        <p className='text-4xl text-gray-700 mb-8'>Aye Lab Presentation Video</p>

                        <video
                            id="my-player"
                            class="video-js"
                            controls
                            preload="auto"
                            //poster="//vjs.zencdn.net/v/oceans.png"
                            oncontextmenu="return false;"
                            controlsList="nodownload"
                            data-setup='{}'>
                            <source src={AyeLabVideo} type="video/mp4"></source>

                            <p class="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a
                                web browser that
                                <a href="https://videojs.com/html5-video-support/" target="_blank">
                                    supports HTML5 video
                                </a>
                            </p>
                        </video>

                    </div>



                </div>



            </div>

            <Footer />
        </div>
    );

}

export default Videos;