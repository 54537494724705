

import { Card } from "flowbite-react";
import Navigation from "../layouts/Navigation";
import { FaRegHospital, FaSatellite, FaSatelliteDish, FaUser, FaUserTie } from "react-icons/fa";
import { FcRefresh } from "react-icons/fc";
import { FaArrowsRotate, FaComputer } from "react-icons/fa6";
import { MdRestartAlt } from "react-icons/md";
import { HiServerStack } from "react-icons/hi2";
import { LuNetwork } from "react-icons/lu";
import Footer from "../layouts/Footer";



let cardTheme = {
    root: {
        children: "flex h-full flex-col justify-start gap-4 p-6",
    }
}


function Services() {


    return (
        <div className="min-h-screen">
            <Navigation />
            <div className="min-h-[160px] bg-[url('assets/images/header_background.png')] px-16 py-12 mt-12">
                <p className="text-white text-5xl">Services</p>
            </div>

            <div className="my-16">

                <div className="flex flex-col gap-12">

                    <div className="flex flex-row flex-wrap gap-8 justify-center px-8 lg:px-16">

                        <div className="basis-full sm:basis-5/12">

                            <Card theme={cardTheme} className="rounded-sm shadow-sm h-full">

                                <div className="flex justify-center items-center gap-4 ">
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                    <FaUser size={32} color="#69cb6a" />
                                    <p className="text-base sm:text-xl text-[#69cb6a]">CUSTOM SOFTWARE DEVELOPMENT</p>
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                </div>
                                <p className="text-gray-700">Aye Technologies produces innovative business solutions that employs innovative data capture methods, uniquely tailored for integration and 'easy to use' software that converts acquired data into useful information. Our team of skilled developers have over 50 years collective experience in creating state-of-the-art software solutions based on proven technologies. Aye Technologies delivers practical software applications designed to meet your business needs in a convenient and user friendly way.</p>

                            </Card>

                        </div>

                        <div className="basis-full sm:basis-5/12">

                            <Card theme={cardTheme} className="rounded-sm shadow-sm h-full justify-start">

                                <div className="flex justify-center items-center gap-4 ">
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                    <FaRegHospital size={32} color="#69cb6a" />
                                    <p className="text-base sm:text-xl text-[#69cb6a]">HEALTH INFORMATION SYSTEMS</p>
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                </div>
                                <p className="text-gray-700">Aye Technologies delivers practical HIS and LIS software applications designed to fit the business model of any Hospital or Laboratory workflow in a convenient and user friendly way. This aspect of our business draws on our considerable experience in developing state-of-the-art software solutions using proven technologies. Additionally, Aye Technologies offers government and private health care institutions a range of software products and support services.</p>

                            </Card>

                        </div>

                    </div>

                    <div className="flex flex-row flex-wrap gap-8 justify-center px-8 lg:px-16">

                        <div className="basis-full sm:basis-5/12">

                            <Card theme={cardTheme} className="rounded-sm shadow-sm h-full">

                                <div className="flex justify-center items-center gap-4 ">
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                    <FaUserTie size={32} color="#69cb6a" />
                                    <p className="text-base sm:text-xl text-[#69cb6a]">TECHNOLOGY CONSULTING SERVICES</p>
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                </div>
                                <p className="text-gray-700">Aye Technologies supplies government and private-sector organizations with a range of IT services covering systems integration, maintenance and support, and outsourcing. Drawing on our extensive experience in building reliable state-of-the-art IT and network systems backed by proven technologies, Aye Technologies delivers practical information systems designed to meet your business needs in a convenient and user friendly way.</p>

                            </Card>

                        </div>

                        <div className="basis-full sm:basis-5/12">

                            <Card theme={cardTheme} className="rounded-sm shadow-sm h-full">

                                <div className="flex justify-center items-center gap-4 ">
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                    <MdRestartAlt size={32} color="#69cb6a" />
                                    <p className="text-base sm:text-xl text-[#69cb6a]">SYSTEMS MAINTENANCE SERVICES</p>
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                </div>
                                <p className="text-gray-700">Aye Technologies supplies equipment required in network implementation to carriers, along with network control platform systems and operating services. Aye Technologies bear a wealth of experience in large-scale network implementation, computer room and strong technical capabilities contribute to the development of highly reliable communications networks.</p>

                            </Card>

                        </div>

                    </div>

                    <div className="flex flex-row flex-wrap gap-8 justify-center px-8 lg:px-16">

                        <div className="basis-full sm:basis-5/12">

                            <Card theme={cardTheme} className="rounded-sm shadow-sm h-full">

                                <div className="flex justify-center items-center gap-4 ">
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                    <HiServerStack size={32} color="#69cb6a" />
                                    <p className="text-base sm:text-xl text-[#69cb6a]">SYSTEM ADMINISTRATION OUTSOURCING</p>
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                </div>
                                <p className="text-gray-700">Aye Technologies supplies government agencies and private-sector companies with products essential to the implementation of IT systems and enterprise network systems, ranging from servers, computers, storage equipment and software to IP telephony systems including SIP servers and soft-phones. Aye Technologies is committed to boosting the performance and energy efficiency of its products to help realize safe, secure, convenient and eco-friendly IT systems.</p>

                            </Card>

                        </div>

                        <div className="basis-full sm:basis-5/12">

                            <Card theme={cardTheme} className="rounded-sm shadow-sm h-full">

                                <div className="flex justify-center items-center gap-4 ">
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                    <LuNetwork size={32} color="#69cb6a" />
                                    <p className="text-base sm:text-xl text-[#69cb6a]">NETWORK INFRASTRUCTURE</p>
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                </div>
                                <p className="text-gray-700">Aye Technologies supplies equipment required in network implementation to carriers, along with network control platform systems and operating services. Aye Technologies bear a wealth of experience in large-scale network implementation, computer room and strong technical capabilities contribute to the development of highly reliable communications networks.</p>

                            </Card>

                        </div>

                    </div>

                    <div className="flex flex-row flex-wrap gap-8 justify-center px-8 lg:px-16">

                        <div className="basis-full sm:basis-5/12">

                            <Card theme={cardTheme} className="rounded-sm shadow-sm h-full">

                                <div className="flex justify-center items-center gap-4 ">
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                    <FaSatelliteDish size={32} color="#69cb6a" />
                                    <p className="text-base sm:text-xl text-[#69cb6a]">MOBILE BUSINESS SOLUTIONS</p>
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                </div>
                                <p className="text-gray-700">Aye Technologies supplies industrial systems that support social infrastructure, including broadcasting systems, artificial satellites and integrated CCTV surveillance systems for airports and local governments, as well as other security-related systems that enhance public safety and security.</p>

                            </Card>

                        </div>

                        <div className="basis-full sm:basis-5/12">

                            <Card theme={cardTheme} className="rounded-sm shadow-sm h-full">

                                <div className="flex justify-center items-center gap-4 ">
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                    <FaComputer size={32} color="#69cb6a" />
                                    <p className="text-base sm:text-xl text-[#69cb6a]">WEB/INTERNET BUSINESS SOLUTIONS</p>
                                    <div className="h-px bg-[#69cb6a] w-5"></div>
                                </div>
                                <p className="text-gray-700">Aye Technologies supplies individuals and private-sector companies with mobile handsets, personal computers, Internet services as well as monitors. It is also engaged in the creation of new devices and services for the cloud computing era. Harnessing cutting-edge technology, Aye Technologies seeks to develop products and services that are simple and convenient for everyone.</p>

                            </Card>

                        </div>

                    </div>

                </div>



            </div>
            
            <Footer />

        </div>
    )


}

export default Services;