import { Route, Routes } from 'react-router';
import './App.css';

import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import Team from './components/pages/Team';
import Videos from './components/pages/Videos';



function App() {


  return (
    <>


      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/team" element={<Team />} />
        <Route path="/videos" element={<Videos />} />
      </Routes>


    </>

  );
}

export default App;
