

function Footer() {
    return (
        <div className='w-full bg-[#314355] p-12'>
            <p className='mb-2 text-white text-2xl'>Contact</p>
            <p className='text-white'>Aye Technologies Limited</p>
            <p className='text-white'>Tel: (876) 978-9524</p>
            <p className='text-white'>Fax: (876) 620-7533</p>
            <p className='text-white'>Address: 8½ Worthington Terrace, Kingston 5, Jamaica</p>
        </div>
    )
}

export default Footer;