import Navigation from "../layouts/Navigation";

import TeamsBG from '../../assets/images/team/IMG_7520.jpg'
import Footer from "../layouts/Footer";
import TeamImg1 from '../../assets/images/team/IMG_7475-Edit.jpg'
import { Card } from "flowbite-react";


let cardTheme = {
    root: {
        base: "flex rounded border border-gray-200 bg-white shadow-sm overflow-hidden dark:border-gray-700 dark:bg-gray-800",
        children: "flex h-full flex-col justify-start gap-0 p-0",
    }
}

function Team() {

    return (
        <div className="min-h-screen h-full flex flex-col">
            <Navigation />
            <div className="grow mt-14 w-full">
                <div className="min-h-[280px] sm:min-h-[400px] lg:min-h-[700px] bg-[url('assets/images/team/IMG_7520.jpg')]  bg-no-repeat  bg-cover mb-8">

                </div>

                <div className='flex flex-col items-center'>
                    <p className='text-3xl mb-14 font-medium text-gray-700'>Our Team</p>

                    <Card
                        theme={cardTheme}
                        className="w-[250px] mb-12">
                        <div className="min-h-[200px] bg-[url('assets/images/team/IMG_7475-Edit.jpg')] bg-no-repeat bg-cover"></div>
                        <div className="p-4 border-t border-gray-200">
                            <h5 className="text-gray-600 font-medium text-lg mb-3">
                                Emerson Brown
                            </h5>
                            <p className="text-gray-600 text-sm">
                                Role: Chief Executive Officer
                            </p>

                        </div>

                    </Card>

                    <div className="flex flex-wrap justify-center mb-12 gap-8">

                        <Card
                            theme={cardTheme}
                            className="w-[250px]">
                            <div className="min-h-[200px] bg-[url('assets/images/team/IMG_7467-Edit.jpg')] bg-no-repeat bg-cover"></div>
                            <div className="p-4 border-t border-gray-200">
                                <h5 className="text-gray-600 font-medium text-lg mb-3">
                                    Emerson H. Brown
                                </h5>
                                <p className="text-gray-600 text-sm">
                                    Role: Technical Director
                                </p>

                            </div>

                        </Card>

                        <Card
                            theme={cardTheme}
                            className="w-[250px]">
                            <div className="min-h-[200px] bg-[url('assets/images/team/IMG_7477-Edit.jpg')] bg-no-repeat bg-cover"></div>
                            <div className="p-4 border-t border-gray-200">
                                <h5 className="text-gray-600 font-medium text-lg mb-3">
                                    Lorna Campbell
                                </h5>
                                <p className="text-gray-600 text-sm">
                                    Role: Secretary
                                </p>

                            </div>

                        </Card>


                    </div>

                    <div className="flex flex-wrap justify-center mb-12 gap-8">

                        <Card
                            theme={cardTheme}
                            className="w-[250px]">
                            <div className="min-h-[200px] bg-[url('assets/images/team/IMG_7497-Edit.jpg')] bg-no-repeat bg-cover"></div>
                            <div className="p-4 border-t border-gray-200">
                                <h5 className="text-gray-600 font-medium text-lg mb-3">
                                    Jermaine Rattray
                                </h5>
                                <p className="text-gray-600 text-sm">
                                    Role: Developer
                                </p>

                            </div>

                        </Card>

                        <Card
                            theme={cardTheme}
                            className="w-[250px]">
                            <div className="min-h-[200px] bg-[url('assets/images/team/IMG_7502-Edit.jpg')] bg-no-repeat bg-cover"></div>
                            <div className="p-4 border-t border-gray-200">
                                <h5 className="text-gray-600 font-medium text-lg mb-3">
                                    Andre Dwyer
                                </h5>
                                <p className="text-gray-600 text-sm">
                                    Role: Developer
                                </p>

                            </div>

                        </Card>

                        <Card
                            theme={cardTheme}
                            className="w-[250px]">
                            <div className="min-h-[200px] bg-[url('assets/images/team/IMG_7448-Edit.jpg')] bg-no-repeat bg-cover"></div>
                            <div className="p-4 border-t border-gray-200">
                                <h5 className="text-gray-600 font-medium text-lg mb-3">
                                    Andre Blair
                                </h5>
                                <p className="text-gray-600 text-sm">
                                    Role: Secretary
                                </p>

                            </div>

                        </Card>


                    </div>

                    <div className="flex flex-wrap justify-center mb-12 gap-8">

                        <Card
                            theme={cardTheme}
                            className="w-[250px]">
                            <div className="min-h-[200px] bg-[url('assets/images/team/IMG_7492-Edit.jpg')] bg-no-repeat bg-cover"></div>
                            <div className="p-4 border-t border-gray-200">
                                <h5 className="text-gray-600 font-medium text-lg mb-3">
                                    Jordan Northover
                                </h5>
                                <p className="text-gray-600 text-sm">
                                    Role: Developer
                                </p>

                            </div>

                        </Card>


                        <Card
                            theme={cardTheme}
                            className="w-[250px]">
                            <div className="min-h-[200px] bg-[url('assets/images/team/IMG_7490-Edit.jpg')] bg-no-repeat bg-cover"></div>
                            <div className="p-4 border-t border-gray-200">
                                <h5 className="text-gray-600 font-medium text-lg mb-3">
                                    Chris-Anne Rowe
                                </h5>
                                <p className="text-gray-600 text-sm">
                                    Role: Developer
                                </p>

                            </div>

                        </Card>


                    </div>

                </div>
            </div>

            <Footer />
        </div>

    )

}

export default Team;