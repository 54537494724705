import { Accordion, Carousel, Navbar } from 'flowbite-react';
import { Link, useLocation } from 'react-router';

import CarouselImg1 from '../../assets/images/digital-marketing.jpg'
import CarouselImg2 from '../../assets/images/businessman.jpg'
import CarouselImg3 from '../../assets/images/technology.jpg';

import Client1 from '../../assets/images/clients/ncb.png';
import Client2 from '../../assets/images/clients/biomed.png';
import Client3 from '../../assets/images/clients/jmmb.jpg';
import Client4 from '../../assets/images/clients/mah.png';
import Client5 from '../../assets/images/clients/unesco.png';

import { FaArrowRight, FaCloud, FaDesktop, FaHdd, FaMobileAlt, FaQuestion, FaRegBuilding, FaUserTie, FaWrench } from 'react-icons/fa';
import { MdComputer, MdKeyboardArrowRight } from 'react-icons/md';
import Navigation from '../../components/layouts/Navigation';
import Footer from '../layouts/Footer';
import { useEffect, useRef } from 'react';

const carouselTheme = {
    scrollContainer: {
        base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
    }
}

const accordionTheme = {
    title: {
        base: "flex w-full items-center justify-between text-xl p-5 bg-blue-200 text-left font-medium text-gray-500 first:rounded-t-lg last:rounded-b-lg dark:text-gray-400",
        open: {
            "off": "bg-gray-100",
            "on": "bg-[#1fa6cd] text-white hover:bg-[#1fa6cd] dark:bg-gray-800 dark:text-white",
        }
    }
}


function Home() {

    let { pathname, hash } = useLocation();

    let clientRef = useRef(null);

    let aboutRef = useRef(null);


    useEffect(() => {
        console.log("changed");
        console.log(hash);
        if (hash.includes("clients")) {
            clientRef.current.scrollIntoView();
        }

        if (hash.includes("about")) {
            aboutRef.current.scrollIntoView();
        }
    }, [hash])


    return (
        <div >
            <Navigation />

            <div className='h-[300px] sm:h-[400px]'>
                <Carousel theme={carouselTheme}>
                    <img src={CarouselImg1} alt="..." />
                    <img src={CarouselImg2} alt="..." />
                    <img src={CarouselImg3} alt="..." />
                </Carousel>
            </div>

            <div className='flex flex-row justify-center  mb-14'>
                <div className='flex flex-row flex-wrap bg-[#40d3f2] max-w-[1140px] mt-[-85px] sm:mt-[-112px] z-20'>

                    <div className='basis-full sm:basis-1/4 p-8 border border-cyan-500' >
                        <div className='flex flex-col items-center'>
                            <div className='mb-2 p-4 rounded-full border-2 border-white w-[70px] h-[70px]'>
                                <FaRegBuilding className='m-auto' size={35} color='white' />
                            </div>

                            <p className='mb-2 text-base font-semibold text-white'>APPLICATION DEVELOPMENT</p>
                            <p className='text-white'>We have the solution for you. We can develop new applications or enhance your existing systems. Our software development methods and quality controls deliver precise applications on time and within budget.</p>
                        </div>
                    </div>

                    <div className='basis-full sm:basis-1/4 p-8 border border-cyan-500' >
                        <div className='flex flex-col items-center'>
                            <div className='mb-2 p-4 rounded-full border-2 border-white w-[70px] h-[70px]'>
                                <FaQuestion className='m-auto' size={35} color='white' />
                            </div>

                            <p className='mb-2 text-base font-semibold text-white'>Why AYE Technologies?</p>
                            <p className='text-white'>As committed and experienced developers, we employ the latest software development methodologies to deliver reliable and innovative solutions designed to meet and even exceed customer expectations.</p>
                        </div>
                    </div>

                    <div className='basis-full sm:basis-1/4 p-8 border border-cyan-500' >
                        <div className='flex flex-col items-center'>
                            <div className='mb-2 p-4 rounded-full border-2 border-white w-[70px] h-[70px]'>
                                <FaUserTie className='m-auto' size={35} color='white' />
                            </div>

                            <p className='mb-2 text-base font-semibold text-white'>IT Consultants</p>
                            <p className='text-white'>Our focus is delivering value to our customers. To achieve this we ensure that the necessary skills and resources are put in place to guarantee that the right solution is delivered at the right time.</p>
                        </div>
                    </div>

                    <div className='basis-full sm:basis-1/4 p-8 border border-cyan-500' >
                        <div className='flex flex-col items-center'>
                            <div className='mb-2 p-4 rounded-full border-2 border-white w-[70px] h-[70px]'>
                                <FaWrench className='m-auto' size={35} color='white' />
                            </div>

                            <p className='mb-2 text-base font-semibold text-white'>Built for you</p>
                            <p className='text-white'>We design software to fit any business model, for enterprises of all sizes and industries. In addition to meeting customer requirements, our products are all designed to enhance management and control.</p>
                        </div>
                    </div>

                </div>
            </div>


            <div className='flex flex-row justify-center mb-14'>
                <div className='flex flex-col items-center'>
                    <p className='text-3xl mb-2 font-medium'>Our Services</p>
                    <p className='mb-20'>Aye Technologies offers a wide range of services</p>

                    <div className='flex flex-row flex-wrap justify-center gap-16 mb-8'>

                        <div className='flex flex-col items-center'>
                            <div className='flex items-center rounded-full shadow-[0_1px_5px_-1px_rgba(0,0,0,0.3)] w-[140px] h-[140px] mb-2'>
                                <MdComputer className='m-auto' size={70} />
                            </div>
                            <p className='text-xl font-bold'>Web</p>
                        </div>


                        <div className='flex flex-col items-center'>
                            <div className='flex items-center rounded-full shadow-[0_1px_5px_-1px_rgba(0,0,0,0.3)] w-[140px] h-[140px] mb-2'>
                                <FaMobileAlt className='m-auto' size={70} />
                            </div>
                            <p className='text-xl font-bold'>Mobile</p>
                        </div>

                        <div className='flex flex-col items-center'>
                            <div className='flex items-center rounded-full shadow-[0_1px_5px_-1px_rgba(0,0,0,0.3)] w-[140px] h-[140px] mb-2'>
                                <FaCloud className='m-auto' size={70} />
                            </div>
                            <p className='text-xl font-bold'>Cloud</p>
                        </div>

                        <div className='flex flex-col items-center'>
                            <div className='flex items-center rounded-full shadow-[0_1px_5px_-1px_rgba(0,0,0,0.3)] w-[140px] h-[140px] mb-2'>
                                <FaHdd className='m-auto' size={70} />
                            </div>
                            <p className='text-xl font-bold'>Device</p>
                        </div>

                        <div className='flex flex-col items-center'>
                            <div className='flex items-center rounded-full shadow-[0_1px_5px_-1px_rgba(0,0,0,0.3)] w-[140px] h-[140px] mb-2'>
                                <FaDesktop className='m-auto' size={70} />
                            </div>
                            <p className='text-xl font-bold'>Desktop</p>
                        </div>

                    </div>

                    <div className='flex items-center mb-4' >
                        <Link to="/services" className='underline italic text-lg'>See more of our services</Link>
                        <MdKeyboardArrowRight size={28} />
                    </div>




                </div>



            </div>

            <div className='flex flex-row justify-center mb-14 py-12 bg-gradient-to-r from-[#43dde6] to-[#9870fc]'>
                <div className='flex flex-col items-center w-1/2'>
                    <p className='text-3xl mb-2 font-medium text-white'>Our Products</p>
                    <p className='text-lg text-white text-center mb-8'>For over 20 years we have serviced the medical industy. We later decided to combine all that collected experience to produce our off-the-shelf Laboratory Information System (LIS)</p>
                    <p className=''></p>
                    <div className='flex items-center mb-4' >
                        <Link to="/products" className='text-white text-lg underline italic'>See more of our products</Link>
                        <MdKeyboardArrowRight size={28} color='white' />
                    </div>
                </div>
            </div>

            <div className='flex flex-row justify-center mb-14'>
                <a id="clients" ref={clientRef} className='block relative top-[-100px]' />
                <div className='flex flex-col items-center w-full'>
                    <p className='text-3xl mb-8 font-medium'>Our Clients</p>

                    <div className='hidden sm:flex flex-row flex-wrap justify-center gap-8'>
                        <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                            <img src={Client1} className='w-full' />
                        </div>
                        <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                            <img src={Client2} className='w-full' />
                        </div>
                        <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                            <img src={Client3} className='w-full' />
                        </div>
                        <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                            <img src={Client4} className='w-full' />
                        </div>
                        <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                            <img src={Client5} className='w-full' />
                        </div>
                    </div>

                    <div className="flex sm:hidden h-56 sm:h-64 xl:h-80 2xl:h-96 w-full">
                        <Carousel>
                            <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                                <img src={Client1} className='w-full' />
                            </div>
                            <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                                <img src={Client2} className='w-full' />
                            </div>
                            <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                                <img src={Client3} className='w-full' />
                            </div>
                            <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                                <img src={Client4} className='w-full' />
                            </div>
                            <div className='w-[222px] h-[222px] rounded-full border-2 border-[#69cb6a] overflow-hidden'>
                                <img src={Client5} className='w-full' />
                            </div>
                        </Carousel>
                    </div>

                </div>
            </div>

            <div className='flex flex-row justify-center mb-14'>
                <a id="about" ref={aboutRef} className='block relative top-[-100px]' />
                <div className='flex flex-col items-center w-full px-8'>
                    <p className='text-3xl mb-8 font-medium'>About Us</p>

                    {/**focus:bg-[#1fa6cd] focus:text-white */}
                    <Accordion theme={accordionTheme} className='w-full'>
                        <Accordion.Panel>
                            <Accordion.Title theme={accordionTheme.title} className=''>Who We Are?</Accordion.Title>
                            <Accordion.Content>
                                <p className="mb-2 text-gray-500 dark:text-gray-400">
                                    We are a team of innovative software engineering enthusiasts whose senior management average over 30 years experience in the Information Technology Industry. We continually seek to demonstrate our potential via the quality of products, services and solutions we provide to our clients.
                                </p>

                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title theme={accordionTheme.title} className=''>Our Motto?</Accordion.Title>
                            <Accordion.Content>
                                <p className="mb-2 text-gray-500 dark:text-gray-400">
                                    We're not wizards, merely methodical, experienced, committed and talented. Thanks to our dedicated personnel we are unmatched in efficiency and value for professional services and affordable custom applications that meet the required business solution
                                </p>

                            </Accordion.Content>
                        </Accordion.Panel>

                        <Accordion.Panel>
                            <Accordion.Title theme={accordionTheme.title} className=''>Our Mission?</Accordion.Title>
                            <Accordion.Content>
                                <p className="mb-2 text-gray-500 dark:text-gray-400">
                                    It is our mission to leverage ground-breaking technologies to produce functional, robust, quality products and solutions that intuitively meets requirements allowing our clients to achieve their highest goals.
                                </p>
                            </Accordion.Content>
                        </Accordion.Panel>

                        <Accordion.Panel>
                            <Accordion.Title theme={accordionTheme.title} className=''>Our Vission?</Accordion.Title>
                            <Accordion.Content>
                                <p className="mb-2 text-gray-500 dark:text-gray-400">
                                    The vision of AYE TECHNOLOGIES is to promote an atmosphere where innovation and creativity can flourish while adhering to our proven engineering methodologies.
                                </p>
                            </Accordion.Content>
                        </Accordion.Panel>

                        <Accordion.Panel>
                            <Accordion.Title theme={accordionTheme.title} className=''>What We Do?</Accordion.Title>
                            <Accordion.Content>
                                <p className="mb-2 text-gray-500 dark:text-gray-400">
                                    AYE TECHNOLOGIES specializes in custom software development for businesses and organizations large and small. We create high performance web based solutions, Enterprise class systems (i.e. banking, health infomatics, hardware interfacing, etc.) and Rich Client application software for almost any platform (Windows, MAC OS or Linux).
                                </p>
                            </Accordion.Content>
                        </Accordion.Panel>


                    </Accordion>

                </div>
            </div>

            <Footer />

        </div>
    );
}

export default Home;
