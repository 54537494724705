import { Navbar } from "flowbite-react";
import { Link, NavLink, useLocation } from "react-router";
import Logo from '../../assets/images/Logo.png'




function Navigation() {

    let {pathname} = useLocation();


    return (<Navbar fluid className="bg-[#f8f9fa] shadow fixed top-0 z-30 w-full">
        <Navbar.Brand as={Link} href="/">
            <img src={Logo} className="mr-3 w-[200px]" alt="Flowbite React Logo" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
            <Navbar.Link as={NavLink} to="/" active={pathname == "/"}>
                Home
            </Navbar.Link>

            <Navbar.Link as={NavLink} to="/services" active={pathname == "/services"}>Services</Navbar.Link>
            <Navbar.Link as={NavLink} to="/products" active={pathname == "/products"}>Products</Navbar.Link>
            <Navbar.Link  href="/#clients" active={pathname == "/#clients"}>Clients</Navbar.Link>
            <Navbar.Link  href="/#about" active={pathname == "/#about"}>About</Navbar.Link>
            <Navbar.Link as={NavLink} to="/team" active={pathname == "/team"}>Team</Navbar.Link>
            <Navbar.Link as={NavLink} to="/videos" active={pathname == "/videos"}>Videos</Navbar.Link>
        </Navbar.Collapse>
    </Navbar>);
}

export default Navigation;