import Footer from "../layouts/Footer";
import Navigation from "../layouts/Navigation";

import ProductImg1 from '../../assets/images/products/aye-lab.png';
import ProductImg2 from '../../assets/images/products/aye-conn.png';



function Products() {

    return (
        <div className="min-h-screen h-full flex flex-col">
            <Navigation />
            <div className="min-h-[160px] bg-[url('assets/images/header_background.png')] px-16 py-12 mt-12">
                <p className="text-white text-5xl">Products</p>
            </div>

            <div className="grow bg-[url('assets/images/img_bg_2.jpg')] bg-fixed bg-center bg-no-repeat bg-cover">
                <div className="flex flex-col items-center py-16 bg-gradient-to-r from-[#43dde6e6] to-[#9870fce6]">
                    <p className="text-white text-[32px] mb-8">Health Information Systems</p>

                    <div className="flex flex-row flex-wrap justify-center sm:justify-start items-center gap-8 px-8 sm:px-16  mb-8">
                        <div className='flex items-center rounded-full shadow-[0_1px_5px_-1px_rgba(0,0,0,0.3)] w-[140px] h-[210px] basis-[210px] mb-2 bg-white overflow-hidden p-4'>
                            <img src={ProductImg1} />
                        </div>
                        <div className="w-full sm:w-2/3">
                            <p className="text-white text-xl font-medium text-center">The ayeLABTM is a Laboratory Information System (LIS) it is built atop our field proven Device Interface Manager (DIM) and Report Distribution System (RDS). Easy to use, flexible, interoperable and secure the ayeLABTM system strictly adheres to the design paradigms of Aye Technologies. The ayeLABTM system is a feature rich LIS and brings genuine value and innovation to the clinical laboratory space.</p>
                        </div>
                    </div>

                    <div className="flex flex-row flex-wrap justify-center sm:justify-start items-center gap-8 px-8 sm:px-16  mb-8">
                        <div className='flex items-center rounded-full shadow-[0_1px_5px_-1px_rgba(0,0,0,0.3)] w-[140px] h-[210px] basis-[210px] mb-2 bg-white overflow-hidden p-4'>
                            <img src={ProductImg2} />
                        </div>
                        <div className="w-full sm:w-2/3">
                            <p className="text-white text-xl font-medium text-center">ayeCONNTM is the data acquisition component of our Device Interface Manager (DIM). Its ability to parse almost any data stream and connect with any Java capable DBMS makes it extremely flexible allowing it to be used to integrate legacy clinical laboratory information systems with modern laboratory analyzers.</p>
                        </div>
                    </div>
                </div>
            </div>




            <Footer />

        </div>
    );
}

export default Products;